
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class OrderHome extends Vue {
  @Prop({ required: true, default: '' })
  public id!: string

  @Prop({ required: true, default: () => [] })
  public labels!: string[]

  @Prop({ required: true, default: () => [] })
  public data!: number[]

  @Prop({ required: true, default: 0 })
  public goal!: number

  @Prop({ default: 0 })
  public secondaryGoal!: number

  @Prop({ default: 50 })
  public height!: number

  public get max() {
    let ret = Math.max(...this.data, this.goal, this.secondaryGoal)
    return ret > 0 ? ret : 1
  }

  public percentage(value: number, max: number) {
    return Math.floor((value / max) * 100)
  }
}
