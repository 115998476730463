
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Api from '@/models/Api'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import moment from 'moment'
import Filter from '@/models/Filter'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import {
  startCase, groupBy as _groupBy, chain as _chain, capitalize,
} from 'lodash'
import DatePickerDate from '@/models/DatePickerDate'
import PacingChart from '@/components/PacingChart/PacingChart.vue'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ScheduleItem from './components/ScheduleItem.vue'
import StatusIndicator from './components/StatusIndicator.vue'

@Component({
  components: {
    Widget,
    ScheduleItem,
    IconAction,
    DatePicker,
    CompanyPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    SelectPicker,
    StatusIndicator,
    PacingChart,
  },
})
export default class ScheduleHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public scope_view: string = 'issue' // issue, level

  public value: Filter = new Filter()

  public enable_charts: boolean = false

  public status_options: SelectOption[] = [
    new SelectOption('Draft', 'draft'),
    new SelectOption('Pending Documents', 'pending_documents'),
    new SelectOption('Pending Review', 'pending_review'),
    new SelectOption('Confirmed', 'confirmed'),
    new SelectOption('Cancelled', 'cancelled'),
    new SelectOption('Lost', 'lost'),
    new SelectOption('Pending Revision', 'pending_revision'),
  ]

  public chartOptions = {
    chart: {
      id: 'vuechart-example',
      toolbar: false,
    },
    xaxis: {
      categories: [] as string[],
    },
  }

  public get chartDates() {
    let ret = []
    let date = moment().subtract(7, 'days')
    for (let i = 0; i < 7; i++) {
      ret.push(date.add(1, 'days').format('MMM, Do YYYY'))
    }

    return ret
  }

  public fields: any = [
    { label: 'Code', key: 'code' },
    { label: 'Type', key: 'media_plan_type' },
    { label: 'Agency', key: 'agency_name', filter: 'agency_id' },
    { label: 'Station', key: 'station_name', filter: 'station_id' },
    { label: 'Advertiser', key: 'advertiser_name', filter: 'advertiser_id' },
    { label: 'Media Plan', key: 'media_plan_name', filter: 'media_plan_id' },
    // { label: 'Media Plan Item', key: 'media_plan_item_name', filter: 'line_item_id' },
    { label: 'Campagin Name', key: 'system_name' },
    {
      label: 'Media Plan Status',
      key: 'status',
      formatter: (i: any) =>
        (i.info.media_plan_status ? startCase(i.info.media_plan_status.replaceAll('_', ' ')) : 'N/A'),
    },
    { label: 'Sales Rep', key: 'sales_rep_name', filter: 'sales_rep_id' },
    {
      label: 'Flight',
      key: 'flight',
      formatter: (i: any) =>
        `${moment(i.info.media_plan_item_start_at).format('MM/DD/YYYY')} - ${moment(
          i.info.media_plan_item_end_at,
        ).format('MM/DD/YYYY')}`,
    },
    { label: 'Days', key: 'days' },
    {
      label: 'Contracted Impression',
      key: 'impressions',
      formatter: (i: any) => this.$options.filters!.abbreviate(i.info.media_plan_item_impressions),
    },
    {
      label: 'Valid Impressions',
      key: 'valid_impressions',
      formatter: (i: any) => {
        let percent = 0
        if (i.info.valid_impressions > 0 && i.info.media_plan_item_impressions > 0) {
          percent = (i.info.valid_impressions / i.info.media_plan_item_impressions) * 100
        }
        return `${this.$options.filters!.abbreviate(i.info.valid_impressions)} (${percent.toFixed(
          2,
        )}%)`
      },
    },
    {
      label: 'Filtered Impressions',
      key: 'filtered_impressions',
      formatter: (i: any) => {
        let percent = 0
        if (i.info.filtered_impressions > 0 && i.info.total_impressions > 0) {
          percent = (i.info.filtered_impressions / i.info.total_impressions) * 100
        }
        return `${this.$options.filters!.abbreviate(
          i.info.filtered_impressions,
        )} (${percent.toFixed(2)}%)`
      },
    },
    {
      label: 'Lifetime Impressions (Including Filtered Impressions)',
      key: 'total_impressions',
      formatter: (i: any) => {
        let percent = 0
        if (i.info.total_impressions > 0 && i.info.media_plan_item_impressions > 0) {
          percent = (i.info.total_impressions / i.info.media_plan_item_impressions) * 100
        }
        return `${this.$options.filters!.abbreviate(i.info.total_impressions)} (${percent.toFixed(
          2,
        )}%)`
      },
    },
    {
      label: 'Completed Impressions',
      key: 'completed',
      formatter: (i: any) => {
        if (i.info.completed) {
          return `${this.$options.filters!.abbreviate(i.info.completed)} (${(
            i.info.completion_rate * 100
          ).toFixed(2)}%)`
        }
        return '0.0 (0.00%)'
      },
    },
    {
      label: 'Target Completed Views',
      key: 'booked_vcr',
      formatter: (i: any) => {
        let ret = '0.00%'
        if (i.info.completed) {
          ret = i.info.completed > i.info.media_plan_item_impressions
            ? '100.00%'
            : `${((i.info.completed / i.info.media_plan_item_impressions) * 100).toFixed(2)}%`
        }

        if (i.info.completed < i.info.media_plan_item_impressions * 0.9) {
          ret += ` (-${this.$options.filters!.abbreviate(
            i.info.media_plan_item_impressions * 0.9 - i.info.completed,
          )})`
        }

        return ret
      },
    },
    {
      label: 'Frequency',
      key: 'frequency',
      formatter: (i: any) => {
        let ret = 'N/A'
        if (i.info.frequency && i.info.frequency > 0) {
          ret = i.info.frequency
        }

        return ret
      },
    },
    {
      label: 'Unique Users',
      key: 'unique_users',
      formatter: (i: any) => {
        let ret = 'N/A'
        if (i.info.unique_users && i.info.unique_users > 0) {
          ret = this.$options.filters!.abbreviate(i.info.unique_users)
        }

        return ret
      },
    },
    { label: 'Notes', key: 'notes' },
  ]

  public data: any = []

  public group_count = 0

  public scope_view_options = [
    { text: 'Severity', value: 'level' },
    { text: 'Issue Type', value: 'issue' },
  ]

  public loading: boolean = false

  public showDetails: string[] = []

  public busy: string[] = []

  public toggleDetails(id: string) {
    this.busy.push(id)
    if (this.showDetails.includes(id)) {
      this.showDetails = this.showDetails.filter(item => item !== id)
    } else {
      this.showDetails.push(id)
    }
    this.busy = this.busy.filter(item => item !== id)
  }

  public shouldShowDetails(id: string) {
    return this.showDetails.includes(id)
  }

  public isBusy(id: string) {
    return this.busy.includes(id)
  }

  public get issues(): any {
    let ret = _groupBy(this.data, 'group')

    this.group_count = Object.keys(ret).length

    for (const [key, value] of Object.entries(ret)) {
      // @ts-ignore
      ret[key] = _groupBy(value, this.scope_view === 'level' ? 'level' : 'type')
    }

    return ret
  }

  public getLevelColor(level: string) {
    return level === 'critical'
      ? '#f5a45d'
      : level === 'danger'
        ? '#f55d5d'
        : level === 'warning'
          ? '#ffc247'
          : level === 'ok'
            ? '#41B883'
            : level === 'completed'
              ? '#e2e3e5'
              : level === 'info'
                ? '#b35df4'
                : '#CCE5FF'
  }

  public mounted() {
    this.value.status = ['confirmed', 'pending_documents']
    this.value.date = new DatePickerDate(
      moment().subtract(10, 'days').format('YYYY-MM-DD'),
      moment().add(10, 'days').format('YYYY-MM-DD'),
      null,
      'YYYY-MM-DD',
    )
    this.run()
  }

  public downloadIssues(filter: string) {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid

    api
      .get('diagnostic/export_issues', {
        type: this.scope_view,
        start: this.value.date.start_date_string,
        end: this.value.date.end_date_string,
        agencies: this.value.agencies,
        advertisers: this.value.advertisers,
        orders: this.value.orders,
        line_items: this.value.line_items,
        sales_reps: this.value.sales_reps,
        status: this.value.status,
        filter,
        instance_id,
      })
      .then(() => {
        WebMessage.success(
          'Your file is being generated. You will be notified once the download is ready, do not close this window.',
        )
      })
  }

  public run() {
    const api = new Api()
    this.loading = true

    api
      .get('diagnostic/issues', {
        type: this.scope_view,
        start: this.value.date.start_date_string,
        end: this.value.date.end_date_string,
        agencies: this.value.agencies,
        advertisers: this.value.advertisers,
        orders: this.value.orders,
        line_items: this.value.line_items,
        sales_reps: this.value.sales_reps,
        status: this.value.status,
      })
      .then(result => {
        this.data = result.data.result
        this.loading = false
      })
  }

  public clickFilter(field: any, item: any) {
    if (field.filter === 'agency_id') {
      this.value.agencies = [item.info[field.filter]]
    } else if (field.filter === 'advertiser_id') {
      this.value.advertisers = [item.info[field.filter]]
    } else if (field.filter === 'media_plan_id') {
      this.value.orders = [item.info[field.filter]]
    } else if (field.filter === 'line_item.info_id') {
      this.value.line_items = [item.info[field.filter]]
    } else if (field.filter === 'sales_rep_id') {
      this.value.sales_reps = [item.info[field.filter]]
    }

    this.run()
  }
}
