
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import Widget from '@/components/Widget/Widget.vue'
import moment from 'moment'
import apexchart from 'vue-apexcharts'
// @ts-ignore
import * as FileSaver from 'file-saver'
import { startCase } from 'lodash'
import StatusIndicator from './StatusIndicator.vue'

@Component({
  components: {
    StatusIndicator,
    IconAction,
    Widget,
    apexchart,
  },
})
export default class ScheduleItem extends ViewModel {
  @Prop({})
  public type!: string

  @Prop({ default: false })
  public busy!: boolean

  @Prop({ default: false })
  public charts!: boolean

  public loader: boolean = false

  @Prop({ default: () => [] })
  public items!: any

  @Prop({ default: () => 'level' })
  public scope_view!: any

  @Prop({ default: () => null })
  public group_name!: any

  public chartOptions = {
    chart: {
      id: 'vuechart-example',
      toolbar: false,
    },
    xaxis: {
      categories: [] as string[],
    },
  }

  public showDetails: boolean = false

  public fields: object[] = [
    { label: 'Code', key: 'code' },
    { label: 'Type', key: 'type' },
    { label: 'Agency', key: 'agency', filter: 'agency_id' },
    { label: 'Advertiser', key: 'client', filter: 'client_id' },
    { label: 'Order', key: 'order', filter: 'order_id' },
    { label: 'Line Item', key: 'name', filter: 'line_item_id' },
    {
      label: 'Media Plan Status',
      key: 'status',
      formatter: (i: any) => (i.status ? startCase(i.status.replaceAll('_', ' ')) : 'N/A'),
    },
    { label: 'Sales Rep', key: 'sales_rep', filter: 'sales_rep_id' },
    {
      label: 'Flight',
      key: 'flight',
      formatter: (i: any) =>
        `${moment(i.start_at).format('MM/DD/YYYY')} - ${moment(i.end_at).format('MM/DD/YYYY')}`,
    },
    { label: 'Days', key: 'days' },
    {
      label: 'Contracted Impression',
      key: 'impressions',
      formatter: (i: any) => this.$options.filters!.abbreviate(i.impressions),
    },
    {
      label: 'Lifetime Impressions',
      key: 'total_impressions',
      formatter: (i: any) =>
        `${this.$options.filters!.abbreviate(i.metrics.impressions)} (${(
          (i.metrics.impressions / i.impressions)
          * 100
        ).toFixed(2)}%)`,
    },
    {
      label: 'Completed Impressions',
      key: 'completed',
      formatter: (i: any) => {
        if (i.completed) {
          return `${this.$options.filters!.abbreviate(i.completed)} (${(
            i.completion_rate * 100
          ).toFixed(2)}%)`
        }
        return '0.0 (0.00%)'
      },
    },
    {
      label: 'Target Completed Views',
      key: 'booked_vcr',
      formatter: (i: any) => {
        let ret = '0.00%'
        if (i.completed) {
          ret = i.completed > i.impressions
            ? '100.00%'
            : `${((i.completed / i.impressions) * 100).toFixed(2)}%`
        }

        if (i.completed < i.impressions * 0.9) {
          ret += ` (-${this.$options.filters!.abbreviate(i.impressions * 0.9 - i.completed)})`
        }

        return ret
      },
    },
    { label: 'Notes', key: 'notes' },
  ]

  public get color() {
    return this.type === 'critical'
      ? '#f5a45d'
      : this.type === 'danger'
        ? '#f55d5d'
        : this.type === 'warning'
          ? '#ffc247'
          : this.type === 'ok'
            ? '#41B883'
            : this.type === 'completed'
              ? '#e2e3e5'
              : this.type === 'vcr'
                ? '#b35df4'
                : '#CCE5FF'
  }

  public get text() {
    if (this.scope_view === 'level') {
      return this.type === 'critical'
        ? 'Campaigns with critical issues'
        : this.type === 'danger'
          ? 'Campaigns with major issues'
          : this.type === 'warning'
            ? 'Campaigns with minor issues'
            : this.type === 'ok'
              ? 'Campaigns without issues'
              : this.type === 'completed'
                ? 'Completed Campaigns'
                : this.type === 'vcr'
                  ? 'Target Completed Views issues'
                  : 'Campaigns coming up'
    }

    return this.group_name
  }

  public clickFilter(field: any, item: any) {
    if (field.filter && item[field.filter]) {
      this.showDetails = false
      this.$emit('onChangeFilter', {
        key: field.filter,
        value: item[field.filter],
      })
    }
  }

  public toggleView() {
    this.loader = true
    setTimeout(() => {
      this.showDetails = !this.showDetails
      this.loader = false
    }, 500)
  }

  public download() {
    this.loader = true
    const data = this.items.reduce(
      (carry: string, i: any) =>
        `${carry}"${i.code}","${i.type}","${i.agency}","${i.client}","${i.order}","${i.name}","${
          i.status
        }","${i.sales_rep}","${moment(i.start_at).format('MM/DD/YYYY')}","${moment(i.end_at).format(
          'MM/DD/YYYY',
        )}","${i.days}","${i.impressions}","${i.metrics.impressions}","${i.completed}","${
          i.notes ?? 'N/A'
        }"\n`,
      '"Code","Type","Agency","Advertiser","Order","Line Item","Status","Sales Rep","Start At","End At","Days","Contracted Impression","Lifetime Impressions","Completed Impressions","Notes"\n',
    )

    const blob = new Blob([data], {
      type: 'data:text/csv;charset=utf-8',
    })
    FileSaver.saveAs(blob, `${this.text.toLowerCase().replaceAll(' ', '_')}.csv`)
    this.loader = false
  }

  public mounted() {
    const start = moment().subtract(6, 'days')
    let count = 0
    const labels = []

    while (count < 7) {
      labels.push(start.format('MM/DD'))
      start.add(1, 'day')
      count++
    }
    this.chartOptions.xaxis.categories = labels
  }
}
